import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../public/reset.css';
import '../public/style.css';
import '@/assets/icon/iconfont.css'
// import BMap from 'bmap'
import store from './store'
import AMap from 'AMap'
import VueLazyload from 'vue-lazyload'
// import lazyload from "vue-j-lazyload";


// import "./flexible"
Vue.config.productionTip = false
// 引入插件
// console.log(VueLazyload,'VueLazyload');
Vue.use(ElementUI);
Vue.use(AMap)
// 注册插件懒加载
// Vue.use(VueLazyload)
Vue.use(VueLazyload,{
  loading:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2F5b0988e595225.cdn.sohucs.com%2Fimages%2F20190201%2Fa1423ae682214e6cae6ba8aa53527db1.gif&refer=http%3A%2F%2F5b0988e595225.cdn.sohucs.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1657936836&t=79c1596968e4a19206a9b888e9921aa4' // 懒加载默认图片
})
// Vue.use(lazyload,{
//   loading:"http://img.zcool.cn/community/012c0258d8bb35a801219c77694def.gif", //可选 
// });
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
