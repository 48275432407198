/*
 * @Author: zizhuang you@example.com
 * @Date: 2024-01-21 18:12:41
 * @LastEditors: zizhuang you@example.com
 * @LastEditTime: 2024-01-26 20:56:26
 * @FilePath: /car_web/src/router/router.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        redirect: "/index",  // 重定向:重新指向其它path,会改变网址
    },

    {
        path: '/home',
        name: 'Home',
        component: () => import('../views/Home.vue'),
        children: [
            {
                path: '/index',
                name: '首页',
                component: () => import('../views/home/Index.vue'),
            },
            {
                path: '/register',
                name: '注册',
                component: () => import('../views/home/register.vue'),
            },
            {
                path: '/notlog',
                name: '未登录',
                component: () => import('../views/home/notlog.vue'),
            },
            {
                path: '/refresh',
                name: '卡密激活',
                component: () => import('../views/home/refresh.vue'),
            },
            {
                path: '/payment',
                name: '志愿卡VIP',
                component: () => import('../views/home/payment.vue'),
            },
            {
                path: '/permissions',
                component: () => import('../views/home/Permissions.vue'),
                children: [{
                    path: '/school',
                    name: '院校查询',
                    component: () => import('../views/home/permissions/school.vue'),
                },
                {
                    path: '/speciality',
                    name: '专业查询',
                    component: () => import('../views/home/permissions/speciality.vue'),
                },
                {
                    path: '/specialityDetail',
                    name: '专业查询',
                    component: () => import('../views/home/permissions/specialityDetail.vue'),
                }
                ]
            },
            {
                path: '/MatchPages',
                component: () => import('../views/home/MatchPages.vue'),
                children: [{
                    path: '/aipermiss',
                    name: 'AI智能填报',
                    component: () => import('../views/home/matchPages/aipermiss.vue'),
                },
                ]
            },
            {
                path: '/SchoolSpecialties',
                component: () => import('../views/home/SchoolSpecialties.vue'),
                children: [{
                    path: '/optional',
                    name: '根据大学专业选高中科目',
                    component: () => import('../views/home/schoolSpecialties/optional.vue'),
                },
                {
                    path: '/courses',
                    name: '根据高中科目选大学专业',
                    component: () => import('../views/home/schoolSpecialties/courses.vue'),
                }
                ]
            },
            {
                path: '/Shortcut',
                component: () => import('../views/home/Shortcut.vue'),
                children: [{
                    path: '/power',
                    name: '位次查询',
                    component: () => import('../views/home/shortcut/power.vue'),
                },
                {
                    path: '/tongfenSchool',
                    name: '同分去向参考',
                    component: () => import('../views/home/shortcut/tongfenSchool.vue'),
                }
                ]
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})
//解决路由重复点击报错问题
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    if (typeof (location) == "string") {
        var Separator = "&";
        if (location.indexOf('?') == -1) { Separator = '?'; }
        location = location + Separator + "random=" + Math.random();
    }
    return routerPush.call(this, location).catch(error => error)
}


export default router
