/*
 * @Author: zizhuang you@example.com
 * @Date: 2022-07-20 10:31:49
 * @LastEditors: zizhuang you@example.com
 * @LastEditTime: 2023-06-26 10:20:54
 * @FilePath: /car_web/src/store/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentIndex: 0,
    carIndex: {}

  },
  mutations: {
    setState(state, payload) {
      // console.log(payload);
      state.currentIndex = payload
    },
    setcarIndex(state, payload) {
      state.carIndex = payload
      // console.log(state.carIndex, '  state.carIndex');
    }
  },
  getters: {

  },
  actions: {
    changeTabIndex({ commit }, payload) {
      // console.log(payload);
      commit('setState', payload)
    },
    carTabIndex({ commit }, payload) {
      commit('setcarIndex', payload)
    }
  },
  modules: {
  }
})
