<!--
 * @Author: 123123 you@example.com
 * @Date: 2022-06-02 17:24:48
 * @LastEditors: zizhuang you@example.com
 * @LastEditTime: 2023-06-25 14:29:31
 * @FilePath: /vue-skypegmwcn/src/App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      screenWidth:0,
    }
  },
  components: {},
 mounted(){
     const that = this;
      window.onresize = () => {
        return (() => {
          window.screenWidth = document.body.clientWidth;
          console.log(this.screenWidth);
          that.screenWidth = window.screenWidth;
          
        })()
      }
 }

};
</script>

<style >
@import '../public/reset.css';

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  /* text-align: center; */
}
body,html{
  min-width:1204px;
  /* border: 1px solid #000; */

}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;height: 0;
}
</style>
